import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Layout from '../../../app/Layout';
import FormularioFiltroReporte from '../../components/ReporteNaranja/FormularioFiltroReporteNaranja';
import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading'
import { updateStateModalBasic } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalBasicwithRedux";
import InfoModal from '../ReporteNaranjaAcademica/infoModal';


//Redux
import { useSelector, useDispatch } from 'react-redux';
import { limpiarResultadoBusqueda, obtenerReporteNaranja } from '../../../actions/finanzasReportesActions';
import ResultadoRecaudacion from './resultadoRecaudacionVr2';
import ResultadoCalidad from './resultadoCalidad';
import ResultadoFidelidad from './resultadoFidelizacion';
import { Card } from 'react-bootstrap';
import { obtenerProyectosActivos } from '../../../actions/cursosActions';

const ReporteNaranja = () => {

  const initialValues = {
    proyectoSeleccionado: [],
    fechaInicio: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // Primer día de este mes
    fechaFin: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1) // Primer día del siguiente mes
  };
  

  const [datosBusqueda, setDatosBusqueda] = useState(null);
  const { loading, error, reporteNaranja } = useSelector(state => state.reporteNaranja);
  const [datos, setDatos] = useState(initialValues);
  const dispatch = useDispatch();
  console.log(reporteNaranja);

  const handleObtenerReporteNaranja = (datos) => {
    //console.log(datos);
    dispatch(limpiarResultadoBusqueda());
    dispatch(obtenerReporteNaranja(datos));
  }

  const handleInfoModal = (tipo) => {
    dispatch(updateStateModalBasic(
      <InfoModal
        tipo={tipo}
      />,
      'Reporte de '+tipo
    ));
  };

  useEffect(() => {
    dispatch(obtenerProyectosActivos()).then((proyectos) => {
      if (proyectos) {
                
        setDatos({
          ...datos,
          proyectoSeleccionado: proyectos
        });
      }
    });
}, []);


  useEffect(() => {
    handleObtenerReporteNaranja(datos);
  }, [datos]);

  return (
    <Layout>
      <div className="ControlReportes">
        <div className="ControlReportes__header">
          <HeaderContent title="Reporte KPI'S" />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "", nombre: "Reportes" },
              { url: "", nombre: "Reporte KPI'S" },
            ]}
          />
        </div>
        <div className="ControlReportes__body">
          <div className="box box-primary">
            <div className="box-body">
              <FormularioFiltroReporte
                datosIniciales={datos}
                onSubmit={handleObtenerReporteNaranja}
                setValores={setDatosBusqueda}
                ingresos
              />
            </div>
          </div>
          <ModalBasicwithRedux />
          <SpinnerLoading loading={loading} error={error}>
            {
              //console.log(reporteNaranja)
              <Card className="p-3 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <Card.Title>Reporte de Recaudacion</Card.Title>
                  <Button variant="primary" onClick={() => handleInfoModal('Recaudacion')} >Ver mas</Button>
                </div>
                <Card.Body>
                  <p className='mb-3'>Este KPI mide el porcentaje mensual de recaudación de alumnos, con una meta del 90%. Se busca garantizar ingresos estables mediante pagos recurrentes, confianza con los alumnos y campañas proactivas de seguimiento.</p>
                  {
                    //console.log(reporteNaranja)
                    <ResultadoRecaudacion recaudacion={reporteNaranja.recaudacion} />
                  }
                </Card.Body>
              </Card>
            }
          </SpinnerLoading>
          <SpinnerLoading loading={loading} error={error}>
            <Card className="p-3 mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <Card.Title>Reporte de Calidad</Card.Title>
                <Button variant="primary" onClick={() => handleInfoModal('Calidad')} >Ver mas</Button>
              </div>              
              <Card.Body>
                <p className='mb-3'>Este KPI mide que los profesores alcancen 90% de satisfacción, evaluando su adaptación a la metodología andragógica. Curaduría los capacita para mejorar su desempeño y asegurar experiencias de aprendizaje efectivas para alumnos adultos.</p>
                {
                  //console.log(reporteNaranja)

                  <ResultadoCalidad calidad={reporteNaranja.calidad} />
                }
              </Card.Body>
            </Card>

          </SpinnerLoading>
          <SpinnerLoading loading={loading} error={error}>
            <Card className="p-3 mb-3">
            <div className="d-flex justify-content-between align-items-center">
                  <Card.Title>Reporte de Retención</Card.Title>
                  <Button variant="primary" onClick={() => handleInfoModal('Retencion')} >Ver mas</Button>
                </div>              
                <Card.Body>
                <p className='mb-3'>Este KPI mide la retención de alumnos mediante pagos, asistencia y acumulación de puntos. La meta es alcanzar 90% de retención, motivando a quienes logren 700 puntos y reconociendo con beneficios académicos a los que superen 1,000 puntos.</p>
                {
                  //console.log(reporteNaranja)
                  <>
                    <ResultadoFidelidad fidelizacion={reporteNaranja.fidelizacion} />
                  </>
                }

              </Card.Body>
            </Card>
          </SpinnerLoading>
        </div>
      </div>
    </Layout>
  );
}

export default ReporteNaranja;